import { Link } from "react-router-dom";
import MiniSidebarItem from "./components/MiniSidebarItem";

export default function MiniSidebar() {
  // Sidebar Items
  const _SIDEBAR = [
    { title: "T.COMPANIES", path: "/companies", icon: "fa-home" },
    { title: "T.USERS", path: "/users", icon: "fa-users" },
    { title: "T.INVOICES", path: "/invoices", icon: "fa-file-invoice-dollar" },
    { title: "T.TRANSACTIONS", path: "/transactions", icon: "fa-money-bill-transfer" },
    { title: "T.INTEGRATIONS", path: "/integrations", icon: "fa-plug" },
    { title: "T.PERMISSION_GROUPS", path: "/permission-groups", icon: "fa-user-shield" },
    { title: "T.NUMBERS", path: "/numbers", icon: "fa-phone" },
    { title: "T.TEMPLATES", path: "/templates", icon: "fa-file-code" },
    { title: "T.VERSIONS", path: "/versions", icon: "fa-code" },
    { title: "T.BUGS", path: "/bugs", icon: "fa-bug" },
  ];

  return (
    <div className="w-64 hidden md:flex flex-shrink-0 border-r flex-col space-y-2 p-2 bg-white">
      {_SIDEBAR.map(({ title, icon, active, path, onClick }, _index) => (
        <Link key={path} to={path}>
          <MiniSidebarItem key={_index} title={title} icon={icon} active={active} />
        </Link>
      ))}
    </div>
  );
}
