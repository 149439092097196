import { API_BASE_URL, CDN_BASE_URL } from ".";

const ADMIN = `${API_BASE_URL}/admin`;

const ENDPOINTS = {
  auth: {
    login: `${API_BASE_URL}/auth/login`,
    verify: `${API_BASE_URL}/auth/verify`,
  },
  account: `${ADMIN}/accounts`,
  company: `${ADMIN}/companies`,
  invoice: `${ADMIN}/invoices`,
  transaction: `${ADMIN}/transactions`,
  number: `${ADMIN}/numbers`,
  numara: `${ADMIN}/numaras`,
  plan: `${ADMIN}/plans`,
  permissionGroup: `${ADMIN}/permission-groups`,
  permission: `${ADMIN}/permissions`,
  user: `${ADMIN}/users`,
  integration: `${ADMIN}/integrations`,
  WAProfile: `${ADMIN}/whatsapp-profiles`,
  template: `${ADMIN}/templates`,
  version: `${ADMIN}/versions`,
  media: `${ADMIN}/medias`,
  bug: `${ADMIN}/bugs`,
  cdn: `${CDN_BASE_URL}/enterprise`,
};

export default ENDPOINTS;
