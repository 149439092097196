import AppIcon from "@components/AppIcon";
import Locale from "@components/core/Locale";
import clx from "classnames";
import { memo } from "react";

const MiniSidebarItem = ({ title, active, onClick, icon }) => {
  return (
    <div
      onClick={onClick}
      className={clx("flex items-center gap-3 py-2.5 px-4 w-full cursor-pointer rounded-lg transition-all duration-200", {
        "bg-primary-50 text-primary-700 font-medium": active,
        "hover:bg-gray-100 text-gray-700": !active,
      })}
    >
      {icon && <AppIcon code={icon} className="w-6 h-6" />}
      <span className={clx("font-medium text-gray-700", { "text-primary-700": active })}>
        <Locale code={title} />
      </span>
      {active && <div className="absolute left-0 w-1 h-5 bg-primary-600 rounded-r-full" />}
    </div>
  );
};

export default memo(MiniSidebarItem);
